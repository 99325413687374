class Enumeration {

    constructor (obj) {
        for (const key in obj) {
            this[ key ] = obj[ key ]
        }
        return Object.freeze(this)
    }

    has (key) {
        return this.hasOwnProperty(key)
    }

    getKey (value) {
        return Object.keys(this).find(key => this[ key ] === value);
    }

    getValue (value) {
        return Object.values(this).find(val => this[ value ] === val);
    }
}

export default Enumeration
