<template>
  <div id="app" translate="no">
    <div class="global--loader" v-if="gloadalLoader">
        <img src="@/assets/images/logo-red.svg" width="40%" alt="logo" class="logo-easy"/>
    </div>
    <router-view ></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  },
  data(){
    return {
      gloadalLoader:true
    }
  },
  watch: {
    $route: {
      immediate: true,
        handler () {
          const content_layout =document.getElementsByClassName('content-wrapper');
          if(this.$route.name=="dashboardClient"){
            if(content_layout&&content_layout[0]){content_layout[0].classList.add("no-padding");}
            }else{
              if(content_layout&&content_layout[0]){content_layout[0].classList.remove("no-padding");}
            }
      }
    }
  },
  created(){
    this.gloadalLoader = false;

  }
}
</script>

<style lang="scss">
@import "./assets/scss/style";
@import "src/assets/css/feather";
</style>
