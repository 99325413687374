class Profile {
    constructor () {
    }
    /**
     *
     * @param role
     * @returns {boolean}
     */
     hasRole (role) {
        let _role = JSON.parse(localStorage.getItem("function")).id;
        return _role === role ? true : false;
    }

    /**
     *
     * @param roles
     * @returns {boolean|*}
     */
    hasRoles (roles) {
        let _role = JSON.parse(localStorage.getItem("function")).id;
         if ( Array.isArray(roles) ) {
            // return _.intersection(roles, _role).length > 0
            return roles.includes(_role)
        }
        else {
            return this.hasRole(roles);
        }
    }
}

export default Profile;
