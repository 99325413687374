import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from '../utils/auth.guard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    redirect: { name: 'phoneClient' },
    component: () => import(/* webpackChunkName: "UserLayout" */ '@/layout/UserLayout'),
    
    children: [
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/pages/user/ForgotPassword'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: 'client/forgot-password',
        name: 'forgotPasswordClient',
        component: () => import(/* webpackChunkName: "ForgotPasswordClient" */ '@/pages/user/ForgotPassword'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/pages/user/ResetPassword'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: 'login/client/phone',
        name: 'phoneClient',
        component: () => import(/* webpackChunkName: "Phone" */ '@/pages/espaceClient/auth/Phone'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: 'client/set-password',
        name: 'setPasswordClient',
        component: () => import(/* webpackChunkName: "SetPassword" */ '@/pages/espaceClient/auth/SetPassword'),
      },
      {
        path: 'login/client',
        name: 'LoginClient',
        component: () => import(/* webpackChunkName: "Login" */ '@/pages/user/Login'),
        meta: {
          loginRequired: false,
        },
      },
    ],
  },
  // dashboard & edit profile
  {
    path: '/',
    component: () =>import('../layout'),
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '/edit-profile',
        name: 'editProfile',
        component: () => import(/* webpackChunkName: "EditProfil" */'@/pages/user/EditProfil'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Editer mon profil' },
          ],
        },
      },
    ],
  },

  // messages
  {
    path: '/messages',
    component: () =>import('../layout'),
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'messages',
        component: () => import(/* webpackChunkName: "messages" */'@/pages/messages/Index'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Messages' }],
          roles: [5],
        },
      },
      {
        path: 'add',
        name: 'addMessage',
        component: () => import(/* webpackChunkName: "addMessage" */'@/pages/messages/Add'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'messages', href: '/messages' },
            { text: 'Créer un message' },
          ],
          roles: [5,1,2,3],
        },
      },
      {
        path: 'edit/:id',
        name: 'editMessage',
        component: () => import(/* webpackChunkName: "editMessage" */'@/pages/messages/Edit'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'messages', href: '/messages' },
            { text: 'Édition un message' },
          ],
          roles: [5],
        },
      },
    ],
  },

  // espace client
  // dashboard & edit profile
  {
    path: '/mobile',
    redirect: { name: 'dashboardClient' },   
    component: () =>import('../layout'),
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: 'dossiers',
        name: 'dashboardClient',
        component: () => import(/* webpackChunkName: "dashboardClient" */'@/pages/espaceClient/dossier/Folders'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/mobile/dossiers' },   
            { text: 'Mes dossiers', href: '/mobile/dossiers' },   
          ],
       //   roles: [4],
          loginRequired: true,
        },
      },  
      {
        path: 'clients',
        name: 'listClient',
        component: () => import(/* webpackChunkName: "clientList" */'@/pages/espaceClient/client/List'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/mobile/dossiers' },   
            { text: 'Liste des clients', href: '/mobile/clients' },   
          ],
       //   roles: [4],
          loginRequired: true,
        },
      },  
      {
        path: 'show/:id',
        // name: 'showDetailsClient',
        name:'detailClientVue',
        component: () => import(/* webpackChunkName: "clientDetails" */'@/pages/espaceClient/client/details/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Liste des clients', href: '/mobile/clients' },   
            { text: 'Détails client', href: 'mobile/clients/show/:id' },      
          ]
        },
      },  
      {
        path: 'payment',
        name: 'payment',
        component: () => import(/* webpackChunkName: "payment" */'@/pages/espaceClient/paiement/PaymentForm'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [
            { text: "Paiement", href: "/mobile/dossier" },
            // { text: "" }
          ],
         roles: [4],
          loginRequired: true,
        },
      },  
    ],
  },
  {
    path: '/mobile/:dossier/:step',
    redirect: { name: 'folderClient' },
    component: () =>import('../layout'),
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'folderClient',
        component: () => import(/* webpackChunkName: "folderClient" */'@/pages/espaceClient/dossier/Index'),
        props: { profil: 1 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/mobile/dossiers' },   
            { text: 'Mes dossiers', href: '/mobile/dossiers' }, 
            { text: '-', href: '' }, 
          ],
        //  roles: [4],
          loginRequired: true,
        },
      },     
    ],
  },
 
  //error pages
  {
    path: '*',
    redirect: '/error-404',
    // component: {
    //     render(c) { return c('router-view') }
    // },
    // component: UserLayout,
    component: () => import(/* webpackChunkName: "UserLayout" */'@/layout/UserLayout'),
    children: [
      {
        path: 'error-404',
        component: () => import(/* webpackChunkName: "Error404" */'@/pages/samples/error-pages/error-404'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: 'error-403',
        name: 'error-403',
        component: () => import(/* webpackChunkName: "Error403" */'@/pages/samples/error-pages/error-403'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: 'unauthorized',
        component: () => import(/* webpackChunkName: "Error401" */'@/pages/samples/error-pages/error-500'),
        meta: {
          loginRequired: false,
        },
      },
    ],
  },
   //securty page
   {
    path: '*',
    redirect: '/',
    // component: {
    //     render(c) { return c('router-view') }
    // },
    // component: UserLayout,
    component: () => import(/* webpackChunkName: "UserLayout" */'@/layout/UserLayout'),
    children: [
      {
        path: 'ref_site/:refSite',
        component: () => import(/* webpackChunkName: "Error404" */'@/pages/samples/security-pages/CoverPage'),
        meta: {
          loginRequired: false,
        },
      }
    ],
  },
]
const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '/',
})

router.beforeEach(AuthGuard)
export default router
