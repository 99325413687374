import { apiUrl, isAuthGuardActive } from '../../constants/config'
import axios from 'axios'
import { stat } from 'fs';

export default {
  state: {
      dateCle:[],
      documents:[],
      dossier:{},
      dossierClient:{},
      dossierStep:{},
      gestionFolder:[],
      dossierParentMessage: null,
      interlocutor:{},
      reponses:[],
      vehicule:[],
      messages:{
        answers:[],
        content:"",
        created_at:"",
        document:{},
        dossier:{
          user:{
            first_name:"",
            last_name:"",
            uuid:""
          }
        },
        id:null,
        respond_to:null,
        title:"",
        user:{
          first_name:"",
          last_name:"",
          uuid:""
        }
      },
      StateLoading: true,
      missingDocs : [],
      uploadedDocs : []  ,
      allFolders : [] ,
      allClients: [],
      getClients:null,
      lastPageFolders : 1,
      isVialinkSetUP: true,
      loadingClients:false,
      currentPageFolders:1,
      dateCle:[],
      client:[],
      clientsDocs:[]
  },
  getters: {
    dossierClient: state => state.dossierClient,
    documents: state => state.documents,
    dossier: state => state.dossier,
    dossierStep: state => state.dossierStep,
    gestionFolder: state => state.gestionFolder,
    reponses: state => state.reponses,
    vehicule: state => state.vehicule,
    StateLoading: state => state.StateLoading,
    messages: state => state.messages,
    dossierParentMessage: state => state.dossierParentMessage,
    missingDocs: state => state.missingDocs,
    uploadedDocs: state => state.uploadedDocs,
    interlocutor : state => state.interlocutor,
    allFolders : state => state.allFolders,
    allClients : state => state.allClients,
    getClients:state => state.getClients,
    currentPageFolders : state => state.currentPageFolders,
    currentPageClients : state => state.currentPageClients,
    lastPageFolders : state => state.lastPageFolders,
    isVialinkSetUP : state => state.isVialinkSetUP,
    loadingClients : state => state.loadingClients,
    dateCle : state =>state.dateCle,
    client : state =>state.client,
    clientsDocs : state => state.clientsDocs,
    loadingDocs: state => state.loadingDocs
  },
  mutations: {
    UPDATE_fOLDER(state,response){
      state.client=response.client;
      state.dateCle=response.dateCle;
      state.documents=response.documents;
      state.dossier=response.dossier;
      state.dossierStep=response.dossier_step;
      state.gestionFolder=response.gestion
      state.reponses=response.reponses;
      state.vehicule=response.vehicule;
      state.interlocutor = response.interlocutor ;
      response.dossier_parent_message ?  state.dossierParentMessage=response.dossier_parent_message.id : state.dossierParentMessage=null ;
    },
    GET_CLIENT(state,response){
      state.dossierClient=response.data;
    },
    SET_LOADING(state, payload) {
      state.StateLoading = payload
    },
    UPDATE_MESSAGES(state,response){
      state.messages=response;
    },
    UPDATE_PARENT(state,id){
      state.dossierParentMessage=id;
    },
    UPDATE_DOCS_MISSING(state, payload){
      state.missingDocs = payload ;
    },
    UPDATE_UPLOADED_DOCS(state, payload){
      state.uploadedDocs = payload
    },
    UPDATE_DOSSIER_STATUS(state, payload){
      state.dossier.statut = payload ;
    },
    SET_FOLDERS(state, payload){
      /*if(payload.length==0){
        state.allFolders = payload
      }else{
        state.allFolders.push(...payload)
      }*/
      state.allFolders = payload
    },
    SET_CLIENTS(state, payload){
      state.allClients = payload
    },
    RESET_FOLDERS(state, payload){
      state.allFolders = []
    },
    RESET_CLIENTS(state, payload){
      state.allClients = []
    },
    RESET_MESSAGES(state){
      state.messages = {
        answers:[],
        content:"",
        created_at:"",
        document:{},
        dossier:{
          user:{
            first_name:"",
            last_name:"",
            uuid:""
          }
        },
        id:null,
        respond_to:null,
        title:"",
        user:{
          first_name:"",
          last_name:"",
          uuid:""
        }
      }
    },
    SET_CLIENTS_LAST_PAGE(state,payload){
      state.lastPageClients = payload
    },
    SET_CLIENTS_CURRENT_PAGE(state,payload){
      state.currentPageClients = payload
    },
    SET_FOLDERS_LAST_PAGE(state,payload){
      state.lastPageFolders = payload
    },
    SET_FOLDERS_CURRENT_PAGE(state,payload){
      state.currentPageFolders = payload
    },
    SET_MISSING_ID_VIALINK(state,payload){
      state.isVialinkSetUP = payload
    },
    SET_LOADING_CLIENTS_DATA(state,payload){
      state.loadingClients = payload
    },
    SET_CLIENTS_DOCS(state,payload){
      state.clientsDocs = payload
    },
    SET_LOADING_CLIENTS_DOCS(state,payload){
      state.loadingDocs = payload
    },

},
  actions: {
    getFolder(context, payload){
      return new Promise((resolve, reject) => {
        context.commit('SET_LOADING', true);
        axios.get(apiUrl + '/api/admin/v1/folder/'+payload.num_dossier).then((response) => {
          if (response.status === 200 || response.status === 201) {
            context.commit('UPDATE_fOLDER', response.data);
            if(response.data.dossier_parent_message){
              context.dispatch('updateMessages') ;
            }
            else{
              context.commit('RESET_MESSAGES')
            }
            resolve(response);
          }
        })
            .catch((error) => {
              console.log("error")
              context.commit('SET_LOADING', false);
              reject(error);
            })
      });

    },

    getClientById(context, payload){
      context.commit('SET_LOADING', true);
      axios.get(apiUrl+'/api/admin/v1/folders/client/'+payload).then((response) => {
      if (response.status === 200 || response.status === 201) {
        context.commit('GET_CLIENT', response.data);

      }})
      .catch((error) => {
        console.log("error")
        context.commit('SET_LOADING', false);
      })
    },
    updateMessages(context){
      axios.get(`${apiUrl}/api/common/v1/message/comments?message_id=${context.getters.dossierParentMessage}`)
      .then(({ data, status }) => {
        context.commit('UPDATE_MESSAGES', data.data);
      },
      err => {
        console.log("error")
      })
    },
    addMessage(context,payload){
      let params ={
        config:{
          num_dossier : context.getters.dossier.dossier_id,
          title:"Dossier "+context.getters.dossier.numero,
          content:payload,
          email_copy:false,
          parent_id:null
        }
      }
      if(context.getters.dossierParentMessage) {
        params.config.parent_id=context.getters.dossierParentMessage
      }
      else {
        params.config.users_uuids = [context.getters.dossier.user_id, context.getters.currentUser.id]
      }
      axios.post(`${apiUrl}/api/admin/v1/messages`, params.config)
      .then(function (response) {
        if(context.getters.dossierParentMessage==null) {
          context.commit('UPDATE_PARENT', response.data.data.id);
        }
        context.dispatch('updateMessages')   ;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getMissingDocs(context, payload){
      let id_vialink=context.getters.client&&context.getters.client.length>0?context.getters.client[0].id_control_client_vialink:null;
      let params = {
        numCmd : context.getters.dossier.numero,
        ...payload
      }
      if(id_vialink!=null){
        axios.get(apiUrl + '/api/admin/v1/vialink/list-docs/'+id_vialink, {params :params}).then((response) => {
          if (response.status === 200 || response.status === 201) {
            context.commit('UPDATE_DOCS_MISSING',response.data)   ;
            context.commit('SET_LOADING', false);
          }
          })
          .catch((error) => {
            console.log("error", error)

            context.commit('SET_LOADING', false);
          })
      } else {
       // context.commit('SET_MISSING_ID_VIALINK', false);
      }

    },
    async getAllFolders(context,payload){
      console.log("payloaed ==>", payload)
      if(payload.page){
        context.commit('SET_FOLDERS',[]);
        context.commit("SET_FOLDERS_CURRENT_PAGE", payload.page)
      }
      context.commit('SET_LOADING', true);
      let params = {
        limit : 18,
        page: payload.page?payload.page: context.getters.currentPageFolders,
        mobile:1,
        search: payload.params ?payload.params.q : payload.q,
        site_id :payload.params ?payload.params.sites : payload.sites,
        statut_id :payload.params ?payload.params.statut_id : payload.statut_id,
      }

     return  axios.get(apiUrl + '/api/admin/v1/folders', {params}).then((response) => {
        if (response.status === 200 || response.status === 201) {
          context.commit('SET_FOLDERS', response.data.data);
          context.commit('SET_FOLDERS_LAST_PAGE', response.data.meta.last_page)
          context.commit('SET_LOADING', false);
        }
      })
      .catch((error) => {
        console.log("error",error)
        context.commit('SET_LOADING', false);
      })
    },
   async getAllClients(context,payload){
      context.commit('SET_LOADING_CLIENTS_DATA', true);
     return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/api/admin/v1/folders/clients', {params :payload}).then((response) => {
        if (response.status == 200 || response.status ==201) {
          resolve(response)
          context.commit('SET_CLIENTS', response.data.data);
          context.commit('SET_LOADING_CLIENTS_DATA', false);
        }
      })
      .catch((error) => {
        context.commit('SET_LOADING_CLIENTS_DATA', false);
        console.log("error",error)
        reject(error)
      })
      });

    },
    async getClientsDocs(context,payload){
      context.commit('SET_LOADING_CLIENTS_DOCS', true);
     return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/api/common/v1/documents/client/'+ payload).then((response) => {
        if (response.status == 200 || response.status ==201) {
          console.log("response ==>", response.data.data)
          context.commit('SET_CLIENTS_DOCS', response.data.data);
          context.commit('SET_LOADING_CLIENTS_DOCS', false);
         resolve(response)
        }
      })
      .catch((error) => {
        context.commit('SET_LOADING_CLIENTS_DOCS', false);
        console.log("error",error)
        reject(error)
      })
      });

    },

  },

}
