import { apiUrl, isAuthGuardActive } from "../../constants/config";
import moment from "moment/src/moment";
import axios from "axios";
export default {
  state: {
    loadingPlanning: false,
    planningWeek: {},
    planningMonth: {},
    planningDay: [],
    availability: [],
    prestations: [],
    showLoadError: false,
    commandesList: [],
    foldersList: [],
    actionsList: [],
    loading: false,
    filtedActionList: [],
    allActionsList: [],
    actionsLabels: [],
    nextFolders: [],
    loadingFolders: false,
    allCalendarFolder: [],
  },
  getters: {
    loadingPlanning: (state) => state.loadingPlanning,
    planningWeek: (state) => state.planningWeek,
    planningDay: (state) => state.planningDay,
    availability: (state) => state.availability,
    planningMonth: (state) => state.planningMonth,
    prestations: (state) => state.prestations,
    showLoadError: (state) => state.showLoadError,
    commandesList: (state) => state.commandesList,
    loading: (state) => state.loading,
    actionsList: (state) => state.actionsList,
    filtedActionList: (state) => state.filtedActionList,
    allActionsList: (state) => state.allActionsList,
    actionsLabels: (state) => state.actionsLabels,
    foldersList: (state) => state.foldersList,
    nextFolders: (state) => state.nextFolders,
    loadingFolders: (state) => state.loadingFolders,
    allCalendarFolder: (state) => state.allCalendarFolder,
  },
  mutations: {
    UPDATE_LOADING(state, payload) {
      state.loadingPlanning = payload;
    },
    UPDATE_PLANNING_DAY(state, payload) {
      state.planningDay = payload;
    },
    UPDATE_PLANNING_WEEK(state, payload) {
      state.planningWeek = payload;
    },
    UPDATE_PLANNING_MONTH(state, payload) {
      state.planningMonth = payload;
    },
    UPDATE_AVAILABILITY(state, payload) {
      state.availability = payload;
    },
    UPDATE_PRESTATIONS(state, payload) {
      state.prestations = payload;
    },
    UPDATE_SHOW_ERROR(state, payload) {
      state.showLoadError = payload;
    },
    SET_FOLDERS_LIST(state, payload) {
      state.commandesList = payload;
    },
    SET_FOLDERS_DIST_LIST(state, payload) {
      state.foldersList = payload;
    },
    UPDATE_FOLDER_ITEM(state, payload) {
      state.commandesList.map((item, index) => {
        if (item.id == payload.id) {
          if (payload.item.data.vehicule)
            state.commandesList[index].vehicule.livraison = {
              ...payload.item.data.vehicule.livraison,
            };
          state.commandesList[index].statut = { ...payload.item.data.statut };
        }
      });
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_FOLDERS_NEXT_LIST(state, payload) {
      state.nextFolders = payload;
    },
    UPDATE_ACTIONS_LIST(state, payload) {
      state.actionsList = [...payload];
    },
    FILTERED_ACTIONS_LIST(state, payload) {
      state.filtedActionList = [...payload];
    },
    ALL_ACTIONS_LIST(state, payload) {
      state.allActionsList = [...payload];
    },
    CHECK_ACTION(state, payload) {
      state.allActionsList.map((item) => {
        if (item.message_id == payload.message) {
          item.is_read = !item.is_read;
        }
      });
      state.filtedActionList = [
        ...state.allActionsList.filter((item) => item.is_read == false),
      ];
      if (payload.checked) {
        state.actionsList = [...state.allActionsList];
      } else {
        state.actionsList = [...state.filtedActionList];
      }
    },
    ALL_LABELS_LIST(state, payload) {
      state.actionsLabels = [...payload];
    },
    SET_SUB_LOADING(state, payload) {
      state.loadingFolders = payload;
    },
    RESET_ACTIONS_LIST(state, payload) {
      (state.filtedActionList = []),
        (state.allActionsList = []),
        (state.actionsList = []);
    },
    SET_ALL_CALENDAR_fOLDERS(state, payload) {
      state.allCalendarFolder = payload;
    },
  },
  actions: {
     

    getReservationPrestation(context, payload) {
      let params = {
        config: {
          reservation: payload.reservation,
          ref_site: payload.ref_site,
          numero_cmd: payload.numero_cmd,
        },
      };
      axios
        .post(apiUrl + "/api/admin/v1/calendar/reservation/test", params.config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            context.commit("UPDATE_PRESTATIONS", response.data);
          }
        });
    },
    // GET FOLDER LIST : RUBRIC AFFECTATION PORTEFUILLE
    getfoldersList({ commit }, payload) {
      commit("SET_LOADING", true);
      let params = {
        statut_id: payload.filter,
        ref_site: [payload.site],
      };
      axios
        .get(apiUrl + "/api/admin/v1/folders", { params: params })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            commit("SET_FOLDERS_LIST", response.data.data);
            commit("SET_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    // GET FOLDER LIST : RUBRIC AFFECTATION PORTEFUILLE
    getfoldersListByDistrubution({ commit }, payload) {
      let params = payload;
      commit("SET_LOADING", true);
      axios
        .get(apiUrl + "/api/admin/v1/folders/listDistrib", { params: params })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            commit("SET_FOLDERS_DIST_LIST", response.data);
            commit("SET_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    // GET FOLDER LIST : RUBRIC AFFECTATION PORTEFUILLE
    loadfoldersList({ commit }, payload) {
      let params = payload;
      commit("SET_SUB_LOADING", true);
      axios
        .get(apiUrl + "/api/admin/v1/folders/listDistrib", { params: params })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            commit("SET_FOLDERS_NEXT_LIST", response.data.data, payload.statut);
            commit("SET_SUB_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    async getListMessagesBySite({ commit, getters }, payload) {
      let site = getters.currentUser.groups.find(
        (item) => item.ref_site == payload.site
      );
      let params = {
        site_id: [site.id],
        is_checkable: 1,
        order: false,
        sort: "message.updated_at",
      };
      await axios
        .get(apiUrl + "/api/common/v1/tracking-messages", { params: params })
        .then((response) => {
          if (response.data.data.length > 0) {
            let data = response.data.data.filter(
              (item) => item.folder.length != 0
            );
            let filteredData = data.filter((item) => item.is_read == true);
            if (data.length > 0) {
              commit("UPDATE_ACTIONS_LIST", data);
              commit("ALL_ACTIONS_LIST", data);
            }
            if (filteredData.length > 0) {
              commit("FILTERED_ACTIONS_LIST", filteredData);
            }
          } else {
            commit("RESET_ACTIONS_LIST");
          }
          axios.post(apiUrl + "/api/admin/v1/labels").then((response) => {
            if (response.data.data.length > 0) {
              commit("ALL_LABELS_LIST", response.data.data);
            }
          });
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    AppendMessageAction({ commit, dispatch, getters, state }, payload) {
      state.actionsList.unshift(payload.item);
      state.allActionsList.unshift(payload.item);
    },
    filterCheckedActionsList({ commit, dispatch, getters }, payload) {
      if (payload.event) {
        let data = [];
        if (getters.allActionsList.length > 0) {
          data = [...getters.allActionsList];
        }
        commit("UPDATE_ACTIONS_LIST", data);
      } else {
        let data = [];
        if (getters.filtedActionList.length > 0) {
          data = [...getters.filtedActionList];
        }
        commit("UPDATE_ACTIONS_LIST", data);
      }
    },
    checkItem({ commit, dispatch, getters }, payload) {
      commit("CHECK_ACTION", payload);
      // dispatch('filterCheckedActionsList',payload.checked)
    },
  },
};
