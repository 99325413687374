import { setCurrentUser, getCurrentUser, checkClientRole } from '.';
import store from '../store/index'

export default (to, from, next) => {
    const isClient = checkClientRole();
    const user = getCurrentUser();
    const  authRoute = ['phoneClient', 'LoginClient', 'setPasswordClient']   
    if (to.matched.some(record => record.meta.loginRequired)) {
        if (user) {
            next();

        } 
        else{
            setCurrentUser(null);   
            next({ name: 'LoginClient' })
        }
    } 
    else {
        if(user && authRoute.some (item => to.name == item)){      
                next({name: 'dashboardClient'}) ;
        }
        else{

            next();
        }    
    }
    // traitement des pages inaccessibles ici 
    if (to.meta.roles) {
        if (!user) return false;
        const roleUser = store.getters.currentUser.fonction;
        if (to.meta.roles.includes(roleUser.id)) {
  
            next()
        } else {
            next({ name: 'error-403' });
        }
    }

}