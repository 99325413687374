import { apiUrl, isAuthGuardActive } from '../../constants/config';
import { setCurrentUser, getCurrentUser } from '../../utils'
import axios from "axios";
import router from '../../router';
import messaging from '../../main';
import {getAuth, signInAnonymously} from 'firebase/auth';
import { getToken } from "firebase/messaging";

export default {
    state: {
        currentUser: isAuthGuardActive ? getCurrentUser() : currentUser,
        loginError: null,
        processing: false,
        forgotMailSuccess: null,
        resetPasswordSuccess: null,
        itemsuser: null,
        process: true,
        isClient: null,
        uid: null,
        phone: null,
        notificationsCount:null,
        allNotifications: [],
        userFcm: null
    },
    getters: {
        currentUser: state => state.currentUser,
        processing: state => state.processing,
        loginError: state => state.loginError,
        forgotMailSuccess: state => state.forgotMailSuccess,
        resetPasswordSuccess: state => state.resetPasswordSuccess,
        itemsuser: state => state.itemsuser,
        process: state => state.process,
        isClient : state => state.isClient,
        uid: state => state.uid,
        phone: state=> state.phone,
        allNotifications : state => state.allNotifications,
        notificationsCount: state => state.notificationsCount,
        userFcm: state => state.userFcm
    },
    mutations: {
        getuserprofil(state, items) {
            state.itemsuser = items
        },
        seteditprofil(state, payload) {
            state.process = payload
        },
        setUser(state, payload) {
            state.currentUser = payload
            state.processing = false
            state.loginError = null
        },
        setLogout(state) {
            state.currentUser = null
            state.processing = false
            state.loginError = null
            state.userFcm =null
        },
        setProcessing(state, payload) {
            state.processing = payload
            state.loginError = null
        },
        setError(state, payload) {
            state.loginError = payload
            state.currentUser = null
            state.processing = false
        },
        setForgotMailSuccess(state) {
            state.loginError = null
            state.currentUser = null
            state.processing = false
            state.forgotMailSuccess = true
        },
        setResetPasswordSuccess(state) {
            state.loginError = null
            state.currentUser = null
            state.processing = false
            state.resetPasswordSuccess = true
        },
        clearError(state) {
            state.loginError = null
        },
        setUid(state, payload) {
            state.uid = payload
        },
        setPhone(state, payload) {
            state.phone = payload
        },
        setNotifications(state, payload){
            state.allNotifications = payload
        },
        setCountNofifications(state, payload){
            state.notificationsCount = payload
        },
        setFcm(state,payload){
            state.userFcm=payload
        }
    },
    actions: {
        login({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            return new Promise((resolve, reject) => {
                axios.post(`${apiUrl}/api/common/v1/auth/login`, payload)
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(data)
                            } 
                                const item = data.data;
                                setCurrentUser(item)
                                commit('setUser', item)
                                localStorage.setItem("function", JSON.stringify(item.fonction))
                        },
                        err => {
                            setCurrentUser(null);
                            commit('setError', err.response.data.message)
                            setTimeout(() => {
                                commit('clearError')
                            }, 3000)
                        })
                    .catch(error => {
                        // reject(error);
                    })
            })
        },
        loginClient({ commit }, payload) {
            commit('clearError')           
            return new Promise((resolve, reject) => {
                axios.post(`${apiUrl}/api/common/v1/auth/login`, payload)
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(true)
                            }
                            const item = data.data;
                            setCurrentUser(item)
                            commit('setUser', item)
                            localStorage.setItem("function", JSON.stringify(item.fonction));

                        },
                        err => {
                            setCurrentUser(null);
                            commit('setError', err.response.data.message)
                            setTimeout(() => {
                                commit('clearError')
                            }, 3000)
                        })
                    .catch(error => {
                        // reject(error);
                    })
            })
        },
        firstLoginClient({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            commit('setPhone', payload.phone)
            commit('setUid', payload.uuid)
            return new Promise((resolve, reject) => {
                axios.post(`${apiUrl}/api/common/v1/auth/first/login`, payload)
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(true)
                            }                          
                            // router.push({name:"setPasswordClient"})

                        },
                        err => {
                            // setCurrentUser(null);
                            commit('setError', err.response.data.message)
                            setTimeout(() => {
                                commit('clearError')
                            }, 3000)
                        })
                    .catch(error => {
                        reject(error);
                    })
            })
        }, 
        signOut({dispatch}){
            axios.post(`${apiUrl}/api/common/v1/auth/logout`)
            .then(
                res => {                   
                    dispatch('logout')
                },
                err => {
                    console.log(err)
                }
            )
            .catch(error => {
                console.log(error)
            })
        }, 
        logout({ commit, state }) {   
                localStorage.clear();
                commit('setLogout');
                router.push({name:'LoginClient'})
                     
        },
        forgotPassword({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/send-reset-link-password`, payload)
                .then(
                    res => {
                        commit('clearError')
                            // commit('setForgotMailSuccess', res.data.message)
                        commit('setForgotMailSuccess', 'Email envoyé avec succès !')
                    },
                    err => {
                        commit('setError', err.response.data.message)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    }

                )
        },
        // verify token
        verifyToken({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/verify-validity-token`, payload)
                .then(
                    res => {},
                    err => {
                        commit('setError', err.response.data)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    }

                )
        },
        resetPassword({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/reset-password`, payload)
                .then(
                    res => {
                        commit('clearError')
                        commit('setResetPasswordSuccess', res.data.message)
                    },
                    err => {
                        commit('setError', err.message)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    }

                )
        },
        setPasswordClient({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/auth/first/login`, payload)
            .then(
                res => {
                    // commit('clearError')
                    // commit('setResetPasswordSuccess', res.data.message)
                    const item = res.data.data;
                    setCurrentUser(item)
                    commit('setUser', item)
                    localStorage.setItem("function", JSON.stringify(item.fonction));
                    router.push({name:"dashboardClient"})
                },
                err => {
                    commit('setError', err.message)
                    setTimeout(() => {
                        commit('clearError')
                    }, 3000)
                }
            )
        },
        getUser({ commit }, payload) {
            axios.get(`${apiUrl}/api/admin/v1/users/${payload.uuid}`)
                .then(({ data, status }) => {
                        commit('getuserprofil', data)
                    },
                    err => {
                        commit('setError', err.message)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    })
        },
        updateUser({ commit }, payload) {
            axios.put(`${apiUrl}/api/common/v1/users/${payload.uuid}`, payload)
                .then(({ data, status }) => {
                    if (status === 200 || status === 204) {
                        commit('seteditprofil', true);
                        setCurrentUser(data.data)
                        commit('setUser', data.data);
                    } else if (status === 422) {
                        commit('seteditprofil', false);
                    }
                }, )
                .catch(error => {
                    commit('setError', error.message)
                    commit('seteditprofil', false)
                    setTimeout(() => {
                        commit('seteditprofil', false)
                    }, 3000)

                })
        },  
        getNotifications({ commit }){
            let params = {
                notification:true,
                order: false,
                sort:'message.updated_at'
            }
            axios.get(`${apiUrl}/api/common/v1/tracking-messages`, {params : params})
            .then((res)=>{
                commit('setNotifications', res.data.data)
                commit('setCountNofifications', res.data.indicators.totalUnReadedMessages)
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        viewItem({ commit, dispatch }, payload){
            let params = {
                status: payload.status 
            }
            axios.put(`${apiUrl}/api/common/v1/update/message/status/${payload.message_id}`, params)
            .then((res)=>{                
                // commit('setNotifications', res.data.data)
                // commit('setCountNofifications', res.data.indicators.totalUnReadedMessages)
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        viewCheckItem({ commit, dispatch }, payload){
            let params = {
                status: payload.status 
            }
            axios.put(`${apiUrl}/api/common/v1/update/message/check/${payload.message_id}`, params)
            .then((res)=>{                
                // commit('setNotifications', res.data.data)
                // commit('setCountNofifications', res.data.indicators.totalUnReadedMessages)
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        async seeAll({ commit, dispatch }){
            await axios.put(`${apiUrl}/api/common/v1/update/unread/message`)
            .then((res)=>{   
                console.log(res,"res")                
            })
            .catch((err)=>{
                console.log(err)
            })

        },
        sendMessageFirebase({ commit, dispatch }){
            let headers= {
                "Content-Type": "application/json",
                "Authorization": "key=AAAAOyXuAkE:APA91bFXsGoSbg8t3sOPlbCHaX0GnJYm8XHEf7-98YdKq7XQ9ZzkYyO4TVwgRL3756Rk5Yn_U7IwmokfXa2LKJOVZeajamiu8TR_Xgl8GvI-5VMsCN0eJu1SskBYuVe53QNq7FALEHtp"
            }
            let payload= {
                "to":"d7XuF5rl0us7T7GJwGb8em:APA91bETAzhvcdjkRM_W3x0FJBKQMuBRplVvk18w996fXCGP3Bhrj9RXCCln23dh9tv9VGc0fU2h2Vb82YwrqEtM1YsHKeBsZnMFPe3Zt_CIW-YC3kmxk4GqBg4f-mkw5W0KhOdcZwbu",
                "data" : {
                    "username" : "anees.baig.barlas",
                    "message": "This is a test message"
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`https://fcm.googleapis.com/fcm/send`,payload, { headers })
                    .then(({ data, status }) => {
                            if (status === 200) {
                                console.log(data,"data")
                                resolve(true)
                            } 
                        },
                        err => {
                            reject(err);
                        })
                    .catch(error => {
                        reject(error);
                    })
            })

        },
        async getFcmFirebase({ commit, dispatch }){
            // await signInAnonymously(getAuth())            
            if(messaging)      
            return getToken(messaging,{vapidKey:'BJPXjn9S_zqHdnGSY-rcIICa8iOksKIG49usB2yxv3NW0UWW4xDTVbDvC4j10LMuYXpr6MYim1l61I9YJptpm_Q'})
            else
            return null
        },   
            
    }
}