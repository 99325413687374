import { apiUrl, isAuthGuardActive } from '../../constants/config'
import axios from 'axios'
export default {
  state: { 
      availblesDates:{},
      nexDate:new Date(),
      successReservation:false,
      loadAttachmentData:{},
      statusList:{},
      folderDetails :null,
      LoadingAides:false,
      sites :[],
      site:null,
      planningsList:[],
      loadingAvailblesDates:false,
      foldersNumberListByClient:[],
      clientFoldersList:[],
      loadingClientFoldersList:false
  },
  getters: {
    availblesDates:state =>state.availblesDates,
    nexDate:state =>state.nexDate,
    successReservation:state =>state.successReservation,
    loadAttachmentData:state =>state.loadAttachmentData,
    statusList:state =>state.statusList,
    folderDetails : state =>state.folderDetails,
    LoadingAides : state => state.LoadingAides,
    sites : state => state.sites,
    site :  state => state.site,
    planningsList : state =>state.planningsList,
    loadingAvailblesDates : state =>state.loadingAvailblesDates,
    foldersNumberListByClient : state => state.foldersNumberListByClient,
    clientFoldersList : state => state.clientFoldersList,
    loadingClientFoldersList : state => state.loadingClientFoldersList
  },
  mutations: {
    SET_AVAILBLES_DATES_LIST(state, payload){
      state.availblesDates = payload ;
    },
    SET_NEXT_DATE(state, payload){
      state.nexDate = payload ;
    },
    SET_SUCCESS_RESERVATION(state, payload){
      state.successReservation = payload ;
    },
    SET_ATTACHMENTS_DATA(state, payload){
      state.loadAttachmentData = payload
    },
    SET_STATUS_LIST(state, payload){
      state.statusList = payload
    },
    SET_FOLDER_DETAILS(state, payload){
      state.folderDetails = payload
    },
    SET_LOADING_AIDES(state, payload){
      state.LoadingAides = payload
    },
    SET_SITES_LIST(state, payload){
      state.sites = payload
    },
    SET_SELECTED_SITE(state, payload){
      state.site = payload
    },
    SET_PLANNINGS_LIST(state, payload){
      state.planningsList = payload
    },
    SET_LOADING_AVAILBLES_DATES(state, payload){
      state.loadingAvailblesDates = payload
    },
    SET_FOLDERS_NUMBERS_LIST(state, payload){
      state.foldersNumberListByClient = payload
    },
    SET_FOLDERS_LIST(state, payload){
      state.clientFoldersList = payload
    },
    SET_LOADER_FOLDERS_CLIENT_LIST(state, payload){
      state.loadingClientFoldersList = payload
    },
  },
  actions: {

    // GET DURATION LIST (AFFECTATION DLL)
   async getDurationList({commit}, payload){
   await   axios.get(apiUrl + '/api/admin/v1/calendar/prestation/'+payload).then((response) => {           
      if (response.status === 200 || response.status === 201) {
       commit('SET_DURATION_LIST',response.data)
      }
      })
      .catch((error) => {
        console.log(error)
      })      
    },
        // GET DURATION LIST (AFFECTATION DLL)
   async getAvailblesDatesList({commit}, payload){
    commit("SET_LOADING_AVAILBLES_DATES",true)
       await   axios.post(apiUrl + '/api/admin/v1/calendar',payload).then((response) => {           
          if (response.status === 200 || response.status === 201) {
            commit('SET_AVAILBLES_DATES_LIST',response.data)
            commit("SET_LOADING_AVAILBLES_DATES",false)
          }
          })
          .catch((error) => {
            console.log(error)
            commit("SET_LOADING_AVAILBLES_DATES",FALSE)
          })      
        },
           // GESTION DES PLANNINGS
   getPlanningsList({commit}, payload){
    axios.get(apiUrl + '/api/admin/v1/planning/'+payload ).then((response) => {           
      if (response.status === 200 || response.status === 201) {
        commit('SET_PLANNINGS_LIST',response.data)
        }
      })
      .catch((error) => {
        console.log("error", error)
      }) 
  },
        // REQUEST FROM RESERVATION LIVRASION DATE.
    saveReservation(context, payload){
      let resultSave = new Promise((resolve, reject) => {
        axios.post(apiUrl + '/api/admin/v1/calendar/reservation',payload)
        .then((response) => { 
          context.commit('SET_SUCCESS_RESERVATION', true) 
          resolve(true);
        })
        .catch(function(error) {
          context.commit('SET_SUCCESS_RESERVATION', false)
          reject(error);
        });
      });   
      return resultSave ;        
    },
    // SET FOLDER STATUS.
    editFolderStatus(context, payload){
      axios.post(apiUrl + `/api/common/v1/update/status/folder/${payload.folder}`,{ "status":payload.status}).then((response) => {           
        if (response.status === 200 || response.status === 201) { 
          context.commit('UPDATE_FOLDER_ITEM', {id:payload.folder ,item:response.data})             
        }
      })
      .catch((error) => {         
        console.log(error)
      })  
    },
    //GET STATUS DISTRUBUTION LIST.
    getStatusList(context, payload){
         new Promise((resolve, reject) => {
            axios.get(apiUrl + '/api/admin/v1/listStatuses',
            {
              params: {
                type_statut : payload
              },
            },)
            .then((response) => { 
              context.commit('SET_STATUS_LIST', response.data) 
              resolve(true);
            })
            .catch(function(error) {

              reject(error);
            });
          });         
        },
    getFolderDetails(context, payload) {
      context.commit('SET_LOADING_AIDES', true);   
      axios.get(apiUrl + `/api/admin/v1/folder/${payload}`).then((response) => {           
        if (response.status === 200 || response.status === 201) { 
          context.commit('SET_FOLDER_DETAILS', response.data);
          context.commit('SET_LOADING_AIDES', false);   
                 
        }
      })
      .catch((error) => {    
        context.commit('SET_LOADING_AIDES', false);       
        console.log(error)
      })  
    },
    getFolderNbrListByClient(context, payload){
      axios.get(apiUrl + '/api/admin/v1/folders/client/'+payload, {
        params: {
          num_only: 1,
        },
    })
    .then((response) => {
      context.commit('SET_FOLDERS_NUMBERS_LIST', response.data) 
    })
    .catch((error) => console.log(error))
    },
    getFolderListByClient(context, payload){
      context.commit('SET_LOADER_FOLDERS_CLIENT_LIST', true) 
      axios.get(apiUrl + '/api/admin/v1/folders/client/'+payload.clientID, {
        params: {
          delivered :payload.delivered,
          tab:payload.tab
        },
    })
    .then((response) => {
      context.commit('SET_FOLDERS_LIST', response.data);
      context.commit('SET_LOADER_FOLDERS_CLIENT_LIST', false) 
    })
    .catch((error) => {console.log(error)
      context.commit('SET_LOADER_FOLDERS_CLIENT_LIST', false) })
    }
    
  },


}
