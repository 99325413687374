import Profile from './Profile';

const Acl = {
    install (Vue, options) {
        // "can" directive accept string with single permission or object containing "permission", and "authorId"
        // v-can:remove="'hi'"
        Vue.directive('can', {
            bind (el, binding, vnode, oldVnode) {

                let profile = new Profile();
                if ( binding.arg === "enable" ) {
                    if ( !profile.hasRoles(binding.value) ) {
                        el.innerHTML = '';
                        el.style.display = "none";
                    }
                }
                if ( binding.arg === "disable" ) {
                    if ( profile.hasRoles(binding.value) ) {
                        el.innerHTML = '';
                        el.style.display = "none";
                    }
                }
                if ( binding.arg === "disable-btn" ) {
                    if ( !profile.hasRoles(binding.value) ) { 
                        el.classList.add('disable');
                    }
                }
                if ( binding.arg === "custom-style" ) {
                    if ( ! profile.hasRoles(binding.value) ) { 
                        el.classList.add('c-style');
                    }
                }
                if ( binding.arg === "change-structure" ) {
                    if ( ! profile.hasRoles(binding.value) ) { 
                        el.classList.add('col-12','c-cards');
                        el.classList.remove('col-xl-6');
                    }
                }
                if ( binding.arg === "bind-class" ) {
                    if (  profile.hasRoles(binding.value) ) {
                        el.classList.add('full-width');
                    }
                }
                if ( binding.arg === "disable-input" ) {
                    if ( !profile.hasRoles(binding.value) ) {
                        el.children.forEach(item => {
                            item.disabled = true;
                            item.querySelectorAll('*').forEach(function (elmt) {
                                elmt.disabled = true;
                                //Set V-select
                                if ( elmt.classList.contains('v-select') ) {
                                    elmt.classList.add('vs--disabled')
                                }
                            });
                        })
                    }
                }
                return true;
            }
        });
        // If authorID id is equal to current userId permission is always granted
        Vue.prototype.$can = function (permission, authorId = false) {
            let profile = new Profile()
            return profile.hasRoles(permission)
        }
    }
};

export default Acl;

