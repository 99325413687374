import { apiUrl} from '../../constants/config'
import axios from 'axios'

export default {
  state: {
      settings:[],
      loadSettings:false,
      succesMajSettings:false,
      succesDeleteSettings:false,
      loadingMAJSettings:false,
      successAddSetting: false,
      successSaveConfigSite:false,
      isMobile:false,
      sitesList: []
  },
  getters: {
    settings: state => state.settings,
    loadSettings: state => state.loadSettings,
    succesMajSettings: state => state.succesMajSettings,
    succesDeleteSettings : state => state.succesDeleteSettings,
    loadingMAJSettings : state => state.loadingMAJSettings,
    successAddSetting : state => state.successAddSetting,
    successSaveConfigSite : state => state.successSaveConfigSite,
    isMobile: state => state.isMobile,
    sitesList : state => state.sitesList
  },
  mutations: {
    SET_CURRENT_DEVICE_STATE(state, payload){
      state.isMobile = payload
    },
    SET_SETTINGS(state, payload){
      state.settings = payload
    },
    SET_LOADING_SETTINGS(state, payload){
      state.loadSettings = payload
    },
    SET_SUCCESS_MAJ_SETTINGS(state, payload){
      state.succesMajSettings = payload
    },
    SET_SUCCESS_DELETE_SETTINGS(state, payload){
      state.succesDeleteSettings = payload
    },
    SET_LOADING_MAJ_SETTINGS(state, payload){
      state.loadingMAJSettings = payload
    },
    SET_SUCCESS_ADD_SETTINGS(state, payload){
      state.successAddSetting = payload
    },
    SET_SUCCESS_CONFIG_SITE(state, payload){
      state.successSaveConfigSite = payload
    },
    SET_SITES_LIST(state, payload){
      state.sitesList = payload
    },
  },
  actions: {
    getSettings({commit}, payload){
      commit('SET_SUCCESS_CONFIG_SITE', false); 
      axios.get(apiUrl + '/api/common/v1/setting/'+payload).then((response) => {           
      if (response.status === 200 || response.status === 201) {
        commit('SET_SETTINGS', response.data); 
        localStorage.setItem('settings', JSON.stringify(response.data))  
        commit('SET_SUCCESS_CONFIG_SITE', true); 
        }
      })
      .catch((error) => {
        console.log("error");
        commit('SET_SUCCESS_CONFIG_SITE', false); 
      })      
    },
    async  getSitesList({commit}, payload){
      let params = {
        site: 1,
      };
      await axios
        .get(apiUrl + "/api/admin/v1/groups", params)
        .then((response) => {
          commit('SET_SITES_LIST', response.data.data)
        console.log("Liste ces sites", response)
        })
        .catch((error) => console.log(error));
   }
  },
}