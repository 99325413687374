import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import user from './modules/user'
import folderClient from './modules/folderClient'
import planningSecretaire from './modules/planningSecretaire'
import folderManagement from './modules/folderManagement'
import settings from './modules/settings'
export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        folderClient,
        planningSecretaire,
        folderManagement,
        settings
    }
})